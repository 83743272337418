<script setup lang="ts">
const { isManager } = useManager()

const links = ref([
  {
    label: 'Home',
    icon: 'i-heroicons-home',
    to: '/',
    disabled: false,
  },
  {
    label: 'Buy',
    to: '/buy',
    icon: 'i-heroicons-shopping-cart',
  },
  {
    label: 'Servers',
    to: '/servers',
    icon: 'i-heroicons-server',
  },
  {
    label: 'Manager',
    to: '/manager/zomro/services',
    icon: 'i-heroicons-cog',
    managerOnly: true,
  },
])

const filteredLinks = computed(() => {
  return links.value.filter((link) => {
    if (link.disabled)
      return false
    if (link.managerOnly && !isManager.value)
      return false
    return true
  })
})
</script>

<template>
  <nav class="order-first md:w-32 border-r border-gray-100 dark:border-gray-800 lg:w-56 p-2 bg-white dark:bg-gray-900 shadow-md overflow-y-auto hidden md:block pt-4">
    <UVerticalNavigation
      :links="filteredLinks"
    />
  </nav>
</template>
