<script lang="ts" setup>
useManager().check()

const chat = useChatWoot()
const user = useSupabaseUser()

onMounted(async () => {
  const { data: me } = await useFetch('/api/me')

  chat.setUser(user.value?.id || 'anom', {
    name: me.value?.full_name || user.value?.email || 'Anom',
    avatar_url: me?.value?.avatar_url || 'https://avatars.dicebear.com/api/avataaars/anom.svg?mood[]=happy',
    email: me.value?.email || user.value?.email,
  })
  chat.setCustomAttributes({
    stripe_customer_id: me.value?.stripe_customer_id || '',
  })
})
</script>

<template>
  <div class="min-h-screen flex flex-col h-screen fixed w-screen ">
    <HeaderMain />
    <div class="flex-1 flex flex-row overflow-hidden">
      <main class="flex-1 overflow-y-auto h-auto p-4">
        <slot />
      </main>
      <SidebarMain />
      <!-- <aside class="sm:w-32 bg-yellow-100 overflow-y-auto">
        Right Sidebar
      </aside> -->
    </div>
    <FooterMain />
  </div>
</template>

<style>

</style>
